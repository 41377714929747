<template>
    <div class="dongtai">
        <div>
            <p class="name">{{title}}</p>
            <div v-html="content" class="maxo"></div>
        </div>
    </div>
</template>

<script>
    import {dynamicData} from "../api/index"
    export default {
        data(){
            return{
                title:'',
                content:'',
                id:'',
                access_id:""
            }
        },
        mounted(){
            this.id = this.$route.query.id
            this.access_id = this.$route.query.access_id
            this.dynamicData()
        },
        methods:{
            async dynamicData(){
                let data = {
                    access_id:this.access_id,
                    id: this.id
                }
                let res = await dynamicData(data)
                this.title = res.data.data.info.title
                this.content = res.data.data.info.content
                console.log(res,"水水水水")
            }
        }
    }
</script>


<style>
body{
    width: 100%;
    height: 100%;
    background-color: #FCF1C3;
}
img{
    width: 100% !important;
}
    .name{
        text-align: center;
        padding: 30px 0 20px 0px;
        color: #AB5F45;
        font-size: 30px;
        font-weight: 900;
    }

    .maxo{
        padding: 0 20px 0 30px ;
        font-size: 30px;
        color: #AB5F45;
    }
</style>